<script lang="ts">
    import { onMount } from 'svelte';

    export let attachment: {
        id: string;
        attachment_key: string;
        path: string;
        type: string;
    };

    let playing = false;
    let button: HTMLButtonElement;

    const helperInit: string = import.meta.env.VITE_AES_KEY;

    async function getEncryptedBase64Content(path: string): Promise<string> {
        // TODO: Implement the logic to fetch and encrypt the content
        return ''; // Placeholder
    }

    let renderAudio = (id: string, encryptedBase64Content: string, type: string) => {
        // TODO: Implement the logic to render the audio
    };

    async function togglePlay() {
        playing = !playing;
        const encryptedBase64Content = await getEncryptedBase64Content(attachment.path);
        renderAudio(attachment.id, encryptedBase64Content, attachment.type);
    }

    onMount(() => {
        // Any initialization logic can go here
    });
</script>

<button
    bind:this="{button}"
    id="audio_{attachment.id}"
    data-helper="{attachment.attachment_key}"
    data-helper-init="{helperInit}"
    data-playing="{playing}"
    role="switch"
    aria-checked="{playing}"
    on:click="{togglePlay}"
>
    <span>{playing ? 'Stop' : 'Play'}</span>
</button>
